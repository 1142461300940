/* eslint-disable react/prop-types */
import {
  Box,
  Modal,
  IconButton,
  styled,
  Typography,
  Paper,
  CircularProgress,
  Slider,
  TextField,
  SliderProps,
  Tooltip,
} from '@mui/material'
import { Close, Info } from '@mui/icons-material'
import { Checkbox, TrackedButton, TrackedLink } from '@flock/shared-ui'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { FUND_CASH_RETURN_DISGUISED_SALE } from '../../../../constants'

const StyledInfoOutline = styled(Info)({
  width: '16px',
  height: '16px',
})

interface AcknowledgementCheckboxProps {
  checked: boolean
  onChange: () => void
  label: string
}

export const AcknowledgementCheckbox: React.FC<
  AcknowledgementCheckboxProps
> = ({ checked, onChange, label }) => (
  <Checkbox
    checked={checked}
    onChange={onChange}
    label={
      <Typography variant="p3" color="error.main">
        {label}
      </Typography>
    }
    color="error"
    sx={{
      color: '#891A1A',
      '&.Mui-checked': {
        color: '#891A1A',
      },
      '& .MuiSvgIcon-root': {
        color: '#891A1A',
      },
    }}
  />
)

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '544px',
  height: '432px',
  padding: '48px',
  borderRadius: '16px',
})

interface StyledSliderProps extends SliderProps {
  acknowledgeSlider?: boolean
}

const StyledSlider = styled(Slider, {
  shouldForwardProp: (prop) => prop !== 'acknowledgeSlider',
})<StyledSliderProps>(({ acknowledgeSlider }) => ({
  color: acknowledgeSlider ? '#891A1A' : 'currentColor',
  '& .MuiSlider-track': {
    height: '2px',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: 'currentColor',
    border: '3px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: acknowledgeSlider ? '#F6EDED' : '#E1EEEF',
    color: acknowledgeSlider ? '#891A1A' : '#03341D',
    borderRadius: 4,
    position: 'absolute',
    top: '70px',
    width: '72px',
    padding: '4px',
    '&::before': {
      bottom: '96%',
    },
  },
}))

const CancelModal = (props: any) => {
  const { open, onClose, onCloseBoth } = props

  const trackingName = 'cancel-modal'

  return (
    <Modal open={open}>
      <Paper
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '544px',
          padding: '48px',
          borderRadius: '16px',
        }}
      >
        <Box display="flex" flexDirection="column" height="100%" gap="32px">
          <SettingsGroupHeaderWrapper sx={{ width: '100%' }}>
            <Typography variant="p1">
              Did you mean to exit Quarterly Cash Flow allotment?
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </SettingsGroupHeaderWrapper>
          <Typography variant="p2">
            Your changes will be lost if you don&apos;t confirm the allotment.
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
          >
            <TrackedButton
              trackingConfig={{ name: `${trackingName}-exit` }}
              variant="secondary"
              size="smallForm"
              onClick={onCloseBoth}
            >
              Exit
            </TrackedButton>
            <TrackedButton
              variant="primary"
              size="smallForm"
              onClick={onClose}
              trackingConfig={{ name: `${trackingName}-continue` }}
            >
              Continue editing
            </TrackedButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  )
}

export type PreferencesModalProps = {
  open: boolean
  cashFlowAllotment: number
  cashFlowSelection: number
  totalEquity: number
  onSubmit: (newValue: number) => Promise<void>
  onClose: () => void
}

const PreferencesModal = (props: PreferencesModalProps) => {
  const {
    open,
    cashFlowAllotment,
    cashFlowSelection,
    totalEquity,
    onSubmit,
    onClose,
  } = props
  const totalEquityQuarter = totalEquity / 4
  const [loading, setLoading] = useState(false)
  const [timeHorizonSlider, setTimeHorizonSlider] = useState(
    cashFlowSelection as number
  )
  const [nextPage, setNextPage] = useState(false)
  const [topUpAcknowledged, setTopUpAcknowledged] = useState(false)
  const [disguisedSaleAcknowledged, setDisguisedSaleAcknowledged] =
    useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const closeBothModals = () => {
    setTimeHorizonSlider(cashFlowSelection as number)
    setNextPage(false)
    setTopUpAcknowledged(false)
    setDisguisedSaleAcknowledged(false)
    setCancelModalOpen(false)
    onClose()
  }

  useEffect(() => {
    setTimeHorizonSlider(cashFlowSelection as number)
  }, [cashFlowSelection])

  const trackingName = 'preferences-modal'

  const submitAndClose = async () => {
    setLoading(true)
    await onSubmit(parseFloat(timeHorizonSlider.toFixed(2)))
    setLoading(false)
    onClose()
  }

  return (
    <Modal open={open}>
      <ModalWrapper>
        <Box display="flex" flexDirection="column" height="100%">
          <SettingsGroupHeaderWrapper sx={{ width: '100%' }}>
            <Typography variant="p1">Quarterly Cash Flow</Typography>
            <IconButton
              onClick={
                timeHorizonSlider !== cashFlowSelection
                  ? () => setCancelModalOpen(true)
                  : onClose
              }
            >
              <Close />
            </IconButton>
          </SettingsGroupHeaderWrapper>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              gap="16px"
              padding={nextPage ? '32px 0px' : '32px'}
            >
              {nextPage ? (
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box display="flex" flexDirection="row">
                    <Typography variant="p2">
                      We&apos;ll deposit{' '}
                      <span
                        style={{
                          color:
                            timeHorizonSlider > cashFlowAllotment ||
                              timeHorizonSlider > FUND_CASH_RETURN_DISGUISED_SALE
                              ? '#891A1A'
                              : '#136D42',
                        }}
                      >
                        $
                        {Math.round(
                          timeHorizonSlider * totalEquityQuarter * 0.01
                        ).toLocaleString()}
                      </span>{' '}
                      into your account each quarter.
                    </Typography>
                  </Box>
                  <hr style={{ width: '100%', color: 'gray2.main' }} />
                  <Typography variant="p2" color="gray6.main">
                    {`Exact dollar amounts are subject to change as quarterly financials are finalized. `}
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  gap="76px"
                >
                  <StyledSlider
                    min={0}
                    value={timeHorizonSlider}
                    defaultValue={cashFlowSelection}
                    onChange={(_, value) =>
                      setTimeHorizonSlider(
                        Array.isArray(value) ? value[0] : value
                      )
                    }
                    max={cashFlowAllotment + 2}
                    step={0.01}
                    acknowledgeSlider={
                      timeHorizonSlider > cashFlowAllotment ||
                      timeHorizonSlider > FUND_CASH_RETURN_DISGUISED_SALE
                    }
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          variant="p2"
                          fontWeight={500}
                        >{`$${Math.round(
                          value * totalEquityQuarter * 0.01
                        ).toLocaleString()}`}</Typography>
                        <Typography variant="p3" fontWeight={500}>
                          per qtr
                        </Typography>
                      </Box>
                    )}
                  />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    gap="8px"
                  >
                    <Typography variant="p2">I want to take</Typography>
                    <Box
                      width="100px"
                      height="40px!important"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap="2px"
                    >
                      <TextField
                        name="flow"
                        type="text"
                        value={timeHorizonSlider.toFixed(2)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            padding: '0px!important',
                          },
                        }}
                        sx={{
                          '& > input': {
                            padding: 0,
                          },
                          '& > div > input': {
                            fontSize: '1rem!important',
                            padding: '0!important',
                            height: '40px!important',
                            color:
                              timeHorizonSlider > cashFlowAllotment ||
                                timeHorizonSlider >
                                FUND_CASH_RETURN_DISGUISED_SALE
                                ? '#891A1A'
                                : '#03341D',
                          },
                        }}
                        InputLabelProps={{
                          style: { padding: '0px!important' },
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          // remove non-numeric characters and trim leading zeros
                          let inputValue = e.target.value.replace(/[^\d]/g, '')
                          inputValue = inputValue.replace(/^0+(?=\d)/, '')
                          // if the input is longer than 3 characters, start from last character
                          if (inputValue.length > 3) {
                            inputValue = inputValue.slice(3)
                          }
                          if (
                            Number(inputValue) / 100 <=
                            cashFlowAllotment + 2
                          ) {
                            setTimeHorizonSlider(Number(inputValue) / 100)
                          } else {
                            setTimeHorizonSlider(cashFlowAllotment + 2)
                          }
                        }}
                      />
                      <Typography variant="p2">%</Typography>
                    </Box>
                    <Typography variant="p2">annualized cash flow</Typography>
                    <StyledTooltip
                      title={
                        <Typography
                          variant="p3"
                          marginRight="6px"
                          color="gray8.main"
                        >
                          Your cash flow allotment percentage is calculated by
                          weighting both existing and new exchanges.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="blendedFCFTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                gap="8px"
              >
                {(() => {
                  const checkboxes = []

                  if (timeHorizonSlider > FUND_CASH_RETURN_DISGUISED_SALE) {
                    checkboxes.push(
                      <AcknowledgementCheckbox
                        key="disguised"
                        checked={disguisedSaleAcknowledged}
                        onChange={() => {
                          setDisguisedSaleAcknowledged(
                            !disguisedSaleAcknowledged
                          )
                        }}
                        label={`I understand that taking cash beyond ${FUND_CASH_RETURN_DISGUISED_SALE}% annually may have tax consequences if within my first 2 years at Flock.`}
                      />
                    )
                  }

                  if (timeHorizonSlider > cashFlowAllotment) {
                    checkboxes.push(
                      <AcknowledgementCheckbox
                        key="topup"
                        checked={topUpAcknowledged}
                        onChange={() => {
                          setTopUpAcknowledged(!topUpAcknowledged)
                        }}
                        label={`I understand that a 10bps penalty will be assessed for every 1% in cash flow I take above my ${cashFlowAllotment}% allotment. This fee compensates other Flock clients.`}
                      />
                    )
                  }

                  // Sort based on which threshold is lower
                  return FUND_CASH_RETURN_DISGUISED_SALE <= cashFlowAllotment
                    ? checkboxes
                    : checkboxes.reverse()
                })()}
              </Box>
            </Box>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {nextPage ? (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <TrackedButton
                      trackingConfig={{ name: `${trackingName}-previous` }}
                      variant="secondary"
                      size="smallForm"
                      onClick={() => setNextPage(false)}
                    >
                      Previous
                    </TrackedButton>
                    <TrackedButton
                      variant="primary"
                      size="smallForm"
                      disabled={
                        (timeHorizonSlider > cashFlowAllotment &&
                          topUpAcknowledged === false) ||
                        (timeHorizonSlider > FUND_CASH_RETURN_DISGUISED_SALE &&
                          disguisedSaleAcknowledged === false)
                      }
                      onClick={submitAndClose}
                      sx={{
                        marginLeft: '1rem',
                      }}
                      trackingConfig={{ name: `${trackingName}-confirmed` }}
                    >
                      Confirm
                    </TrackedButton>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    flexDirection="row"
                    alignItems="center"
                    gap="16px"
                  >
                    <TrackedLink
                      trackingConfig={{ name: `${trackingName}-cancelled` }}
                      variant="p1"
                      onClick={
                        timeHorizonSlider !== cashFlowSelection
                          ? () => setCancelModalOpen(true)
                          : onClose
                      }
                    >
                      Cancel
                    </TrackedLink>
                    <TrackedButton
                      variant="primary"
                      size="smallForm"
                      disabled={
                        (timeHorizonSlider > cashFlowAllotment &&
                          topUpAcknowledged === false) ||
                        (timeHorizonSlider > FUND_CASH_RETURN_DISGUISED_SALE &&
                          disguisedSaleAcknowledged === false)
                      }
                      onClick={() => setNextPage(true)}
                      sx={{
                        marginLeft: '1rem',
                      }}
                      trackingConfig={{ name: `${trackingName}-next` }}
                    >
                      Next
                    </TrackedButton>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
        <CancelModal
          open={cancelModalOpen}
          onClose={() => setCancelModalOpen(false)}
          onCloseBoth={closeBothModals}
        />
      </ModalWrapper>
    </Modal>
  )
}

export default PreferencesModal
