import { useMutation, useQuery } from '@apollo/client'
import {
  Core_OrderV3SellingEntityInformationCompletedTaskRequestData,
  Core_OrderV3TaskRequestData_DataOneof_SellingEntityInformationCompletedTaskRequestData,
  InvestorCreateLegalEntityDocumentDocument,
  InvestorGetOrderV3Document,
  InvestorUpdateOrderV3TaskDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { InputType, PageContainer, StepConfig } from '@flock/shared-ui'
import { Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { RouteComponentProps, useParams } from '@reach/router'
import { DENVER_ADDRESS, ORDERS_URL } from '../../../../constants'
import PageWrapper from '../../../SharedComponents/PageWrapper'
import FormStepComponent, {
  FormStepComponentProps,
  InjectedFormStepProps,
} from '../../FormStepComponent'
import StepperForm from '../../StepperForm'
import LoadingCardPage from '../../../SharedComponents/LoadingCardPage'
import { useInvestorAccountContext } from '../../../InvestorAccountContext'
import { flattenEntityInformationData } from './api'
import ErrorCardPage from '../../../SharedComponents/ErrorCardPage'
import {
  EntityInformationData,
  EntityInformationFunctions,
} from './entityInformationTypes'

const progressDisplaySteps = [
  {
    label: 'Entity Information',
    value: 'entityInformation',
  },
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EntityInformationPage = (_: RouteComponentProps) => {
  const { investorContextLoading } = useInvestorAccountContext()

  const { orderUuid, taskUuid, legalEntityUuid } = useParams()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [requestData, setRequestData] = useState(
    {} as Core_OrderV3SellingEntityInformationCompletedTaskRequestData
  )
  const [updateEntityInformationTask] = useMutation(
    InvestorUpdateOrderV3TaskDocument
  )
  const [createLegalEntityDocument] = useMutation(
    InvestorCreateLegalEntityDocumentDocument
  )

  const { refetch: refetchOrders } = useQuery(InvestorGetOrderV3Document, {
    skip: true,
  })

  const fetchRecentOrder = useCallback(async () => {
    try {
      const { data: orderData, error: orderError } = await refetchOrders({
        input: {
          uuid: orderUuid,
        },
      })
      if (orderError) {
        setError(true)
      }
      const order = orderData?.getOrderV3?.order
      const orderLegalEntity = order?.orderLegalEntities?.find(
        (le) => le.legalEntityUuid === legalEntityUuid
      )
      orderLegalEntity?.sellingEntities?.forEach((se) => {
        const entityTask = se?.sellingEntityProcess?.find(
          (sep) => sep?.taskUuid === taskUuid
        )
        if (entityTask) {
          setRequestData(
            (
              entityTask?.requestData
                ?.data as Core_OrderV3TaskRequestData_DataOneof_SellingEntityInformationCompletedTaskRequestData
            )
              ?.sellingEntityInformationCompletedTaskRequestData as Core_OrderV3SellingEntityInformationCompletedTaskRequestData
          )
        }
      })

      setLoading(false)
    } catch (e) {
      setError(true)
    }
  }, [orderUuid, legalEntityUuid, taskUuid, refetchOrders])

  useEffect(() => {
    if (!investorContextLoading && orderUuid) {
      fetchRecentOrder()
    }
  }, [investorContextLoading, orderUuid, fetchRecentOrder])

  if (error) {
    return <ErrorCardPage />
  }
  if (loading || investorContextLoading) {
    return <LoadingCardPage text="Loading Entity Information Form..." />
  }

  const injectProps =
    (stepName: keyof EntityInformationData) =>
    (flowData: Partial<EntityInformationData>) => ({
      flowData,
      stepData: flowData[stepName] || {},
    })

  const funcs: EntityInformationFunctions = {
    updateEntityInformationTask,
    createLegalEntityDocument,
  }

  const entityInfoStep: StepConfig<
    EntityInformationData,
    EntityInformationFunctions,
    FormStepComponentProps,
    InjectedFormStepProps
  > = {
    injectProps: injectProps('entityInformation'),
    onBack: () => navigate(`${ORDERS_URL}/${orderUuid}`),
    stepName: 'entityInformation',
    component: FormStepComponent,
    onCompletion: async (
      flowData: Partial<EntityInformationData>,
      functions: EntityInformationFunctions
    ) => {
      await flattenEntityInformationData(
        {
          ...flowData,
          legalEntityUuid,
        } as EntityInformationData,
        functions
      )
      return flowData
    },
    componentProps: {
      stepName: 'entityInfo',
      progress: 'entityInformation',
      backText: 'Return to Order',
      inputConfigs: [
        {
          name: 'entityInformation',
          type: InputType.Section,
          props: {
            inputConfigs: [
              {
                name: 'entityName',
                type: InputType.Text,
                required: true,
                props: {
                  label: 'Entity name',
                  placeholder: 'Entity Name',
                },
              },
              {
                name: 'entityMailingAddress',
                type: InputType.Address,
                required: true,
                props: {
                  label: 'Entity mailing address',
                  placeholder: DENVER_ADDRESS,
                },
              },
              {
                name: 'entityDocuments',
                type: InputType.FileUpload,
                required: true,
                props: {
                  label: (
                    <>
                      <Typography variant="p2">
                        Please upload the required entity documents,
                      </Typography>
                      <br />
                      <br />
                      <Typography variant="p3" sx={{ color: 'gray5.main' }}>
                        For a trust, please update a fully executed Certificated
                        of Trust or Full Trust Agreement and addendums. For an
                        LLC/partnership, please upload a Certificate of Good
                        Standing, Articles of Incorporation or Certificate of
                        Formation, and an Operating Agreement or LLC Agreement.
                      </Typography>
                    </>
                  ),
                  acceptDefaults: true,
                },
              },
              {
                name: 'entityEin',
                type: InputType.Text,
                required: true,
                renderIf: (watchedFields) =>
                  watchedFields.entityIdType === 'ein',
                gridItemProps: {
                  xs: 9,
                },
                props: {
                  label: 'Tax identification number for entity (EIN or SSN)',
                  format: 'ein',
                  placeholder: '12-3456789',
                },
              },
              {
                name: 'entitySsn',
                type: InputType.Text,
                required: true,
                renderIf: (watchedFields) =>
                  watchedFields.entityIdType === 'ssn',
                gridItemProps: {
                  xs: 9,
                },
                props: {
                  label: 'Tax identification number for entity (EIN or SSN)',
                  format: 'ssn',
                  placeholder: '123-45-6789',
                },
              },
              {
                name: 'entityIdType',
                type: InputType.Dropdown,
                required: true,
                gridItemProps: {
                  xs: 3,
                },
                props: {
                  label: ' ',
                  defaultValue: 'ein',
                  options: [
                    {
                      label: 'ein',
                      value: 'ein',
                    },
                    {
                      label: 'ssn',
                      value: 'ssn',
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  }

  const stepConfigs = [entityInfoStep]
  const prefillData = {
    entityName: requestData?.name,
  }

  return (
    <PageContainer
      title="FLOCK | Owner Information"
      trackingName="entity-information"
    >
      <PageWrapper>
        <Grid container spacing={4} height="auto">
          <StepperForm
            title="Entity Information"
            initialData={{
              taskUuid,
              orderUuid,
              entityInformation: {
                ...prefillData,
              },
            }}
            stepConfigs={stepConfigs}
            progressDisplaySteps={progressDisplaySteps}
            functions={funcs}
            useStepComponentLoading
            useHashRouting={false}
            useStepComponentError
          />
        </Grid>
      </PageWrapper>
    </PageContainer>
  )
}

export default EntityInformationPage
