import { formatPercentage } from '@flock/utils'
import {
  FundPerformanceHeaderProps,
  UseFundPerformanceHeaderData,
} from './fundPerformanceHeaderTypes'

const useFundPerformanceHeader = (
  props: FundPerformanceHeaderProps
): UseFundPerformanceHeaderData => {
  const {
    selectedQuarterTotalGainLossOnRealEstate,
    selectedQuarterTotalNetIncome,
    selectedQuarterFundValueChange,
    selectedQuarterTotalFundValuation,
    shouldShowDollarView,
  } = props
  if (shouldShowDollarView) {
    return {
      gainLossIsLoss: selectedQuarterTotalGainLossOnRealEstate < 0,
      gainLossString: Math.abs(
        selectedQuarterTotalGainLossOnRealEstate
      ).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      netIncomeString: selectedQuarterTotalNetIncome.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      fundValueChangeString: selectedQuarterFundValueChange.toLocaleString(
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      ),
      fundValueChangeIsLoss: selectedQuarterFundValueChange < 0,
    }
  } else {
    return {
      gainLossIsLoss: selectedQuarterTotalGainLossOnRealEstate < 0,
      gainLossString: formatPercentage(
        Math.abs(
          selectedQuarterTotalGainLossOnRealEstate /
            selectedQuarterTotalFundValuation
        ),
        1
      ),
      netIncomeString: formatPercentage(
        selectedQuarterTotalNetIncome / selectedQuarterTotalFundValuation,
        1
      ),
      fundValueChangeString: formatPercentage(
        selectedQuarterFundValueChange / selectedQuarterTotalFundValuation,
        1
      ),
      fundValueChangeIsLoss: selectedQuarterFundValueChange < 0,
    }
  }
}

export default useFundPerformanceHeader
