import {
  Box,
  Typography,
  Paper,
  Slider,
  styled,
  TextField,
  SliderProps,
} from '@mui/material'
import { investorCalculateFlexibleCashFlowModel } from '@flock/utils/src/money/investorFlexibleCashFlowCalculator'
import {
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ComposedChart,
} from 'recharts'
import React, { ChangeEvent, useCallback, useState } from 'react'
import millify from 'millify'
import { FlexibleCashFlowPresentationalProps } from './flexibleCashflowCalculatorTypes'

interface StyledSliderProps extends SliderProps {
  topup?: boolean
}

const StyledSlider = styled(Slider, {
  shouldForwardProp: (prop) => prop !== 'topup',
})<StyledSliderProps>(({ topup }) => ({
  color: topup ? '#891A1A' : 'currentColor',
  '& .MuiSlider-track': {
    height: '2px',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: 'currentColor',
    border: '3px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: topup ? '#F6EDED' : '#E1EEEF',
    color: topup ? '#891A1A' : '#03341D',
    borderRadius: 4,
    position: 'absolute',
    top: '70px',
    width: '72px',
    padding: '4px',
    '&::before': {
      bottom: '96%',
    },
  },
}))

const StyledComposedChart = styled(ComposedChart)({
  '& .recharts-cartesian-grid > .recharts-cartesian-grid-horizontal > line:nth-last-child(2)':
    {
      display: 'none!important',
    },
})

const shortenIfGreaterThan1M = (amt: number) => {
  if (amt < 1000000) {
    return `$${Math.round(amt).toLocaleString()}`
  }

  const millfiedAmount = millify(amt!, {
    precision: 2,
    lowercase: true,
    space: false,
    units: ['', 'k', 'm', 'b', 't'],
  })

  return `$${millfiedAmount}`
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        sx={{
          background: 'gray1.main',
          padding: '12px',
          borderRadius: '8px',
          boxShadow: `0px 8px 32px 0px rgba(91, 115, 139, 0.24)`,
        }}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="p2" color="moneyGreen.main">
            Account Value
          </Typography>

          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            gap="4px"
          >
            <Typography
              variant="p3"
              sx={{ paddingLeft: '4px' }}
            >{`High (12%) : `}</Typography>
            <Typography
              variant="p3"
              sx={{ paddingLeft: '4px' }}
            >{`$${Math.round(
              payload[0].value[0]
            ).toLocaleString()}`}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            gap="4px"
          >
            <Box
              display="flex"
              flexDirection="row"
              gap="2px"
              alignItems="center"
            >
              <Box
                width="3px"
                height="14px"
                sx={{
                  background: 'moneyGreen.main',
                  color: 'black',
                  backgroundColor: 'moneyGreen.main',
                }}
              />
              <Typography
                variant="p3"
                sx={{ fontWeight: 500 }}
              >{`Average (10%) : `}</Typography>
            </Box>
            <Typography variant="p3" sx={{ fontWeight: 500 }}>{` $${Math.round(
              payload[1].value
            ).toLocaleString()}`}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            gap="4px"
          >
            <Typography
              variant="p3"
              sx={{ paddingLeft: '4px' }}
            >{`Low (8%) : `}</Typography>
            <Typography
              variant="p3"
              sx={{ paddingLeft: '4px' }}
            >{`$${Math.round(
              payload[0].value[1]
            ).toLocaleString()}`}</Typography>
          </Box>
        </Box>
      </Paper>
    )
  }

  return null
}

const FlexibleCashflowCalculatorDesktop = (
  props: FlexibleCashFlowPresentationalProps
) => {
  const {
    startingInvestment,
    cashFlowSelection,
    cashFlowAllotment,
    holdingYears,
    showCashFlowDeductions,
    isTablet,
  } = props

  const tickFormatterY = useCallback((val: any) => {
    const precision = val / 100 < 100000 || val / 100 > 1000000 ? 1 : 0 // if val is between 100k and 1m, show no decimal places since we will overflow on the chart
    return `$${millify(val, {
      precision,
      lowercase: true,
      units: ['', 'K', 'M', 'B', 'T'],
    })}`
  }, [])

  const [cashFlowSlider, setCashFlowSlider] = useState(cashFlowSelection)
  const flexibleCashFlowOutputs = investorCalculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield: cashFlowAllotment,
    cashFlowPercentage: 0.01 * cashFlowSlider,
    holdingPeriodOverride: holdingYears,
    hideNetYield: false,
  })
  const totalCashFlowTaken = flexibleCashFlowOutputs.cashWithdrawnAvg.reduce(
    (sum, cashWithdrawn) => sum + cashWithdrawn,
    0
  )
  const endingFirstYearBalanceForCashFlow =
    flexibleCashFlowOutputs.initialAccountValueAvg[0]
  const totalEquityQuarter = endingFirstYearBalanceForCashFlow / 4

  // we use noCashFlowOutputs to calculate the upper bound of projections in 5 years so the graph ticks can stay consistent
  const noCashFlowOutputs = investorCalculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield: cashFlowAllotment,
    cashFlowPercentage: 0,
    holdingPeriodOverride: holdingYears,
    hideNetYield: false,
  })
  const graphDomain = [
    noCashFlowOutputs.initialAccountValueLow[0],
    noCashFlowOutputs.initialAccountValueHigh[4],
  ]

  const data = [
    {
      name: 'Year 1',
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[0],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[0],
        flexibleCashFlowOutputs.initialAccountValueLow[0],
      ],
    },
    {
      name: 'Year 2',
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[1],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[1],
        flexibleCashFlowOutputs.initialAccountValueLow[1],
      ],
    },
    {
      name: 'Year 3',
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[2],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[2],
        flexibleCashFlowOutputs.initialAccountValueLow[2],
      ],
    },
    {
      name: 'Year 4',
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[3],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[3],
        flexibleCashFlowOutputs.initialAccountValueLow[3],
      ],
    },
    {
      name: 'Year 5',
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[4],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[4],
        flexibleCashFlowOutputs.initialAccountValueLow[4],
      ],
    },
  ]

  const PotentialAnnualEarnings = (
    <>
      <Box>
        <Typography variant="p2" color="gray8.main">
          My total Flock account value
        </Typography>
      </Box>
      <Box>
        <Typography
          fontSize="32px"
          lineHeight="40px"
          fontWeight="600"
          color="moneyGreen.main"
        >
          ${startingInvestment.toLocaleString()}
        </Typography>
      </Box>
      <Box>
        <Typography variant="c1" color="green3.main">
          Target annualized return of 8-12% comprised of cash flow &
          appreciation
        </Typography>
      </Box>
    </>
  )

  return (
    <Box display="flex" flexDirection="column" gap="24px" width="100%">
      {isTablet ? (
        <>
          <Box display="flex" flexDirection="column" gap="24px">
            <Box display="flex" flexDirection="column" gap="24px">
              <Box display="flex" gap="8px" flexDirection="column">
                {PotentialAnnualEarnings}
              </Box>
              <Paper
                sx={{
                  padding: '16px',
                  backgroundColor: 'gray1.main',
                  borderRadius: '8px',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  gap="76px"
                  padding="0px 32px"
                >
                  <StyledSlider
                    min={0}
                    value={cashFlowSlider}
                    defaultValue={4}
                    onChange={(_, value) =>
                      setCashFlowSlider(Array.isArray(value) ? value[0] : value)
                    }
                    max={cashFlowAllotment + 2}
                    step={0.01}
                    topup={cashFlowSlider > cashFlowAllotment}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          variant="p2"
                          fontWeight={500}
                        >{`$${Math.round(
                          value * (totalEquityQuarter * 1.1) * 0.01
                        ).toLocaleString()}`}</Typography>
                        <Typography variant="p3" fontWeight={500}>
                          per qtr
                        </Typography>
                      </Box>
                    )}
                  />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    gap="8px"
                  >
                    <Typography variant="p2">I want to take</Typography>
                    <Box
                      width="160px"
                      height="40px!important"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap="2px"
                    >
                      <TextField
                        name="flow"
                        type="number"
                        value={cashFlowSlider.toFixed(2)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          min: 0,
                          step: 0.01,
                          precision: 2,
                          style: {
                            textAlign: 'center',
                            padding: '0px!important',
                          },
                          inputMode: 'numeric',
                        }}
                        sx={{
                          '& > input': {
                            padding: 0,
                          },
                          width: '160px',
                          '& > div > input': {
                            fontSize: '1rem!important',
                            padding: '0!important',
                            height: '40px!important',
                            color:
                              cashFlowSlider > cashFlowAllotment
                                ? '#891A1A'
                                : '#03341D',
                          },
                        }}
                        InputLabelProps={{
                          style: { padding: '0px!important' },
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          // remove non-numeric characters and trim leading zeros
                          let inputValue = e.target.value.replace(/[^\d]/g, '')
                          inputValue = inputValue.replace(/^0+(?=\d)/, '')
                          // if the input is longer than 3 characters, start from last character
                          if (inputValue.length > 3) {
                            inputValue = inputValue.slice(3)
                          }
                          if (
                            Number(inputValue) / 100 <=
                            cashFlowAllotment + 2
                          ) {
                            setCashFlowSlider(Number(inputValue) / 100)
                          } else {
                            setCashFlowSlider(cashFlowAllotment + 2)
                          }
                        }}
                      />
                      <Typography variant="p2">%</Typography>
                    </Box>
                    <Typography variant="p2">annualized cash flow</Typography>
                  </Box>
                </Box>
              </Paper>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Box display="flex">
                    <Box width="100%">
                      <Box display="flex" gap="4px" flexDirection="column">
                        <Box
                          display="flex"
                          gap="8px"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography variant="p2" color="gray8.main">
                            Staying Invested Year 1
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h4"
                            color="moneyGreen.main"
                            sx={{
                              fontWeight: 600,
                            }}
                          >
                            +$
                            {Math.round(
                              flexibleCashFlowOutputs.totalReturnAvg[0]
                            ).toLocaleString()}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="c1" color="gray5.main">
                            +
                            {(
                              (flexibleCashFlowOutputs.totalReturnAvg[0] /
                                startingInvestment) *
                              100
                            ).toFixed(1)}
                            % total return
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box width="100%">
                      <Box display="flex" gap="4px" flexDirection="column">
                        <Box
                          display="flex"
                          gap="8px"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography
                            variant="p2"
                            color="gray8.main"
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            Staying Invested Years 1-5
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h4"
                            color="moneyGreen.main"
                            sx={{
                              fontWeight: 600,
                            }}
                          >
                            +$
                            {Math.round(
                              flexibleCashFlowOutputs.finalAccountValueAvg[4] -
                                startingInvestment +
                                totalCashFlowTaken
                            ).toLocaleString()}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="c1" color="gray5.main">
                            +
                            {(
                              ((flexibleCashFlowOutputs
                                .finalAccountValueAvg[4] -
                                startingInvestment +
                                totalCashFlowTaken) /
                                startingInvestment) *
                              100
                            ).toFixed(1)}
                            % total return
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="column">
                <Box>
                  <ResponsiveContainer width="100%" height={300}>
                    <StyledComposedChart
                      width={524}
                      height={300}
                      data={data}
                      margin={{ top: 0, right: 5, left: 5, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="4 1 2" />
                      <XAxis
                        dataKey="name"
                        style={{ fontSize: '12px', fontWeight: 300 }}
                      />
                      <YAxis
                        width={40}
                        scale="linear"
                        interval="preserveStartEnd"
                        domain={graphDomain}
                        tickFormatter={tickFormatterY}
                        tickCount={3}
                        style={{ fontSize: '12px', fontWeight: 300 }}
                      />
                      <Tooltip
                        content={<CustomTooltip active payload label />}
                      />
                      <Legend />
                      <Area
                        legendType="none"
                        type="monotone"
                        dataKey="range"
                        fill="#C9DADB"
                        stroke="#C9DADB"
                      />
                      <Line
                        legendType="none"
                        type="monotone"
                        dataKey="Total Return"
                        stroke="#03341D"
                        strokeWidth={2}
                      />
                    </StyledComposedChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
              <Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb="8px"
                  alignItems="center"
                >
                  <Box width="100px" />
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    Year 1
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    Year 2
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    Year 3
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    Year 4
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    Year 5
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb="8px"
                  alignItems="center"
                >
                  <Typography
                    variant="p2"
                    width="100px"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Account value
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.initialAccountValueAvg[0]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.initialAccountValueAvg[1]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.initialAccountValueAvg[2]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.initialAccountValueAvg[3]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.initialAccountValueAvg[4]
                    )}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb="8px"
                  alignItems="center"
                >
                  <Typography
                    variant="p2"
                    color="gray8.main"
                    width="100px"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Total return
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    color="gray8.main"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.totalReturnAvg[0]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    color="gray8.main"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.totalReturnAvg[1]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    color="gray8.main"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.totalReturnAvg[2]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    color="gray8.main"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.totalReturnAvg[3]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    color="gray8.main"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.totalReturnAvg[4]
                    )}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb="8px"
                  alignItems="center"
                >
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="100px"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Cash taken
                  </Typography>
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.cashWithdrawnAvg[0]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.cashWithdrawnAvg[1]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.cashWithdrawnAvg[2]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.cashWithdrawnAvg[3]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.cashWithdrawnAvg[4]
                    )}
                  </Typography>
                </Box>
                {showCashFlowDeductions && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    pb="8px"
                    alignItems="center"
                  >
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="100px"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;Deductions
                    </Typography>
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {shortenIfGreaterThan1M(
                        flexibleCashFlowOutputs.cashDeductionAvg[0]
                      )}
                    </Typography>
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {shortenIfGreaterThan1M(
                        flexibleCashFlowOutputs.cashDeductionAvg[1]
                      )}
                    </Typography>
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {shortenIfGreaterThan1M(
                        flexibleCashFlowOutputs.cashDeductionAvg[2]
                      )}
                    </Typography>
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {shortenIfGreaterThan1M(
                        flexibleCashFlowOutputs.cashDeductionAvg[3]
                      )}
                    </Typography>
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {shortenIfGreaterThan1M(
                        flexibleCashFlowOutputs.cashDeductionAvg[4]
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="c1" color="green3.main">
              Changes to this section apply only to projections on this page. To
              edit quarter-end cash-flow elections, enter Settings from the main
              view of your Client Portal.
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" flexDirection="row" gap="24px">
            <Box display="flex" flexDirection="column" gap="24px" width="50%">
              <Box display="flex" gap="8px" flexDirection="column">
                {PotentialAnnualEarnings}
              </Box>
              <Paper
                sx={{
                  padding: '16px',
                  backgroundColor: 'gray1.main',
                  borderRadius: '8px',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  gap="76px"
                  padding="0px 32px"
                >
                  <StyledSlider
                    min={0}
                    value={cashFlowSlider}
                    defaultValue={4}
                    onChange={(_, value) =>
                      setCashFlowSlider(Array.isArray(value) ? value[0] : value)
                    }
                    max={cashFlowAllotment + 2}
                    step={0.01}
                    topup={cashFlowSlider > cashFlowAllotment}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          variant="p2"
                          fontWeight={500}
                        >{`$${Math.round(
                          value * (totalEquityQuarter * 1.1) * 0.01
                        ).toLocaleString()}`}</Typography>
                        <Typography variant="p3" fontWeight={500}>
                          per qtr
                        </Typography>
                      </Box>
                    )}
                  />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    gap="8px"
                  >
                    <Typography variant="p2">I want to take</Typography>
                    <Box
                      width="160px"
                      height="40px!important"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap="2px"
                    >
                      <TextField
                        name="flow"
                        type="number"
                        value={cashFlowSlider.toFixed(2)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          min: 0,
                          step: 0.01,
                          precision: 2,
                          style: {
                            textAlign: 'center',
                            padding: '0px!important',
                          },
                          inputMode: 'numeric',
                        }}
                        sx={{
                          '& > input': {
                            padding: 0,
                          },
                          width: '160px',
                          '& > div > input': {
                            fontSize: '1rem!important',
                            padding: '0!important',
                            height: '40px!important',
                            color:
                              cashFlowSlider > cashFlowAllotment
                                ? '#891A1A'
                                : '#03341D',
                          },
                        }}
                        InputLabelProps={{
                          style: { padding: '0px!important' },
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          // remove non-numeric characters and trim leading zeros
                          let inputValue = e.target.value.replace(/[^\d]/g, '')
                          inputValue = inputValue.replace(/^0+(?=\d)/, '')
                          // if the input is longer than 3 characters, start from last character
                          if (inputValue.length > 3) {
                            inputValue = inputValue.slice(3)
                          }
                          if (
                            Number(inputValue) / 100 <=
                            cashFlowAllotment + 2
                          ) {
                            setCashFlowSlider(Number(inputValue) / 100)
                          } else {
                            setCashFlowSlider(cashFlowAllotment + 2)
                          }
                        }}
                      />
                      <Typography variant="p2">%</Typography>
                    </Box>
                    <Typography variant="p2">annualized cash flow</Typography>
                  </Box>
                </Box>
              </Paper>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Box display="flex">
                    <Box width="100%">
                      <Box display="flex" gap="4px" flexDirection="column">
                        <Box
                          display="flex"
                          gap="8px"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography variant="p2" color="gray8.main">
                            Staying Invested Year 1
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h4"
                            color="moneyGreen.main"
                            sx={{
                              fontWeight: 600,
                            }}
                          >
                            +$
                            {Math.round(
                              flexibleCashFlowOutputs.totalReturnAvg[0]
                            ).toLocaleString()}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="c1" color="gray5.main">
                            +
                            {(
                              (flexibleCashFlowOutputs.totalReturnAvg[0] /
                                startingInvestment) *
                              100
                            ).toFixed(1)}
                            % total return
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box width="100%">
                      <Box display="flex" gap="4px" flexDirection="column">
                        <Box
                          display="flex"
                          gap="8px"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography
                            variant="p2"
                            color="gray8.main"
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            Staying Invested Years 1-5
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h4"
                            color="moneyGreen.main"
                            sx={{
                              fontWeight: 600,
                            }}
                          >
                            +$
                            {Math.round(
                              flexibleCashFlowOutputs.finalAccountValueAvg[4] -
                                startingInvestment +
                                totalCashFlowTaken
                            ).toLocaleString()}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="c1" color="gray5.main">
                            +
                            {(
                              ((flexibleCashFlowOutputs
                                .finalAccountValueAvg[4] -
                                startingInvestment +
                                totalCashFlowTaken) /
                                startingInvestment) *
                              100
                            ).toFixed(1)}
                            % total return
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" width="50%">
              <Box display="flex" flexDirection="column">
                <Box>
                  <ResponsiveContainer width="100%" height={295}>
                    <StyledComposedChart
                      width={524}
                      height={295}
                      data={data}
                      margin={{ top: 0, right: 5, left: 5, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="4 1 2" />
                      <XAxis
                        dataKey="name"
                        style={{ fontSize: '12px', fontWeight: 300 }}
                      />
                      <YAxis
                        width={40}
                        scale="linear"
                        interval="preserveStartEnd"
                        domain={graphDomain}
                        tickFormatter={tickFormatterY}
                        tickCount={3}
                        style={{ fontSize: '12px', fontWeight: 300 }}
                      />
                      <Tooltip
                        content={<CustomTooltip active payload label />}
                      />
                      <Legend />
                      <Area
                        legendType="none"
                        type="monotone"
                        dataKey="range"
                        fill="#C9DADB"
                        stroke="#C9DADB"
                      />
                      <Line
                        legendType="none"
                        type="monotone"
                        dataKey="Total Return"
                        stroke="#03341D"
                        strokeWidth={2}
                      />
                    </StyledComposedChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
              <Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb="8px"
                  alignItems="center"
                >
                  <Box width="100px" />
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    Year 1
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    Year 2
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    Year 3
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    Year 4
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    Year 5
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb="8px"
                  alignItems="center"
                >
                  <Typography
                    variant="p2"
                    width="100px"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Account value
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.initialAccountValueAvg[0]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.initialAccountValueAvg[1]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.initialAccountValueAvg[2]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.initialAccountValueAvg[3]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.initialAccountValueAvg[4]
                    )}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb="8px"
                  alignItems="center"
                >
                  <Typography
                    variant="p2"
                    color="gray8.main"
                    width="100px"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Total return
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    color="gray8.main"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.totalReturnAvg[0]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    color="gray8.main"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.totalReturnAvg[1]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    color="gray8.main"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.totalReturnAvg[2]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    width="80px"
                    color="gray8.main"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.totalReturnAvg[3]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    color="gray8.main"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.totalReturnAvg[4]
                    )}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb="8px"
                  alignItems="center"
                >
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="100px"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Cash taken
                  </Typography>
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.cashWithdrawnAvg[0]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.cashWithdrawnAvg[1]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.cashWithdrawnAvg[2]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.cashWithdrawnAvg[3]
                    )}
                  </Typography>
                  <Typography
                    variant="p2"
                    fontWeight="300"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {shortenIfGreaterThan1M(
                      flexibleCashFlowOutputs.cashWithdrawnAvg[4]
                    )}
                  </Typography>
                </Box>
                {showCashFlowDeductions && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    pb="8px"
                    alignItems="center"
                  >
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="100px"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;Deductions
                    </Typography>
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {shortenIfGreaterThan1M(
                        flexibleCashFlowOutputs.cashDeductionAvg[0]
                      )}
                    </Typography>
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {shortenIfGreaterThan1M(
                        flexibleCashFlowOutputs.cashDeductionAvg[1]
                      )}
                    </Typography>
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {shortenIfGreaterThan1M(
                        flexibleCashFlowOutputs.cashDeductionAvg[2]
                      )}
                    </Typography>
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {shortenIfGreaterThan1M(
                        flexibleCashFlowOutputs.cashDeductionAvg[3]
                      )}
                    </Typography>
                    <Typography
                      variant="p2"
                      fontWeight="300"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {shortenIfGreaterThan1M(
                        flexibleCashFlowOutputs.cashDeductionAvg[4]
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="c1" color="green3.main">
              Changes to this section apply only to projections on this page. To
              edit quarter-end cash-flow elections, enter Settings from the main
              view of your Client Portal.
            </Typography>
          </Box>
        </>
      )}
    </Box>
  )
}

export default FlexibleCashflowCalculatorDesktop
