import {
  Core_BalanceSheetRow,
  Core_GetBalanceSheetPerQuarterResponse,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useStaticQuery, graphql } from 'gatsby'
import { useEffect, useState } from 'react'
import { sortBy } from 'lodash'
import {
  BalanceSheetColumn,
  BalanceSheetTableData,
} from './balanceSheetPageTypes'
import { formatDollarsAndCentsInvestor } from '../../utils/formatting'

const convertRowAmount = (row: Core_BalanceSheetRow | undefined): string =>
  row?.amountCents
    ? formatDollarsAndCentsInvestor(row.amountCents / 100, false, false)
    : '-'

const convertRowInt = (row: Core_BalanceSheetRow | undefined): number =>
  row?.amountCents ? row.amountCents / 100 : 0

const useBalanceSheetPage = (): BalanceSheetTableData => {
  const [quarters, setQuarters] = useState<string[]>([])
  const [selectedQuarter, setSelectedQuarter] = useState('')
  const [balanceSheetData, setBalanceSheetData] =
    useState<Core_GetBalanceSheetPerQuarterResponse>()
  const [selectedQuarterBalanceSheetData, setSelectedQuarterBalanceSheetData] =
    useState<BalanceSheetColumn>({} as BalanceSheetColumn)
  // data
  const {
    getBalanceSheetPerQuarter: balanceSheet,
  }: {
    getBalanceSheetPerQuarter: Core_GetBalanceSheetPerQuarterResponse
  } = useStaticQuery(graphql`
    query InvestorBalanceSheetPage {
      getBalanceSheetPerQuarter {
        balanceSheetRows {
          amountCents
          quarterString
          rowName
        }
      }
    }
  `)

  useEffect(() => {
    setBalanceSheetData(balanceSheet)
    const balanceSheetQuarters = Array.from(
      new Set(balanceSheet.balanceSheetRows.map((row) => row.quarterString))
    )

    const uniqueQuarters = Array.from(new Set(balanceSheetQuarters))
    const sortedUniqueQuarters = sortBy(
      uniqueQuarters,
      (q: string) => `${q.split(' ')[1]} ${q.split(' ')[0]}`
    ) as string[]

    setQuarters(sortedUniqueQuarters)
    setSelectedQuarter(sortedUniqueQuarters[sortedUniqueQuarters.length - 1])
  }, [balanceSheet])

  useEffect(() => {
    const currentQuarterData = balanceSheetData?.balanceSheetRows?.filter(
      (row) => row.quarterString === selectedQuarter
    )

    const totalCurrentAssets = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Total current assets')
    )

    const totalCashAndRestrictedCash = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Total cash and restricted cash'
      )
    )

    const totalAccountsReceivable = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Total account receivable'
      )
    )

    const dueFromPropertyManager = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Due from property manager'
      )
    )

    const dueFromPropertyManagerSecurityDeposits = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Due from property manager - security deposits'
      )
    )

    const accountsReceivable = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Accounts receivable')
    )

    const accruedInterestReceivable = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Accrued interest receivable'
      )
    )

    const insuranceClaimsReceivable = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Insurance claims receivable'
      )
    )

    const otherReceivables = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Other receivables')
    )

    const badDebtWriteOff = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Bad debt write-off')
    )

    const utilityReimbursementReceivable = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Utility reimbursement receivable'
      )
    )

    const totalOtherCurrentAssets = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Total other current assets'
      )
    )

    const prepaidAssociationDues = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Prepaid association dues'
      )
    )

    const prepaidFees = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Prepaid fees')
    )

    const prepaidPropertyInsurance = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Prepaid property insurance'
      )
    )

    const prepaidPropertyTaxes = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Prepaid property taxes'
      )
    )

    const utilityDeposits = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Utility deposits')
    )

    const escrowDeposits = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Escrow deposits')
    )

    const otherDeposits = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Other deposits')
    )

    const treasurySecurities = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Treasury securities')
    )

    const totalFixedAssets = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Total fixed assets')
    )

    const buildingInt = convertRowInt(
      currentQuarterData?.find((row) => row.rowName === 'Buildings')
    )

    const land = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Land')
    )

    const landInt = convertRowInt(
      currentQuarterData?.find((row) => row.rowName === 'Land')
    )

    const buildingImprovementsStabilizing = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Capex - stabilizing')
    )

    const buildingImprovementsStatusQuo = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Capex - status quo')
    )

    const unrealizedGainLoss = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Unrealized gain-loss')
    )

    const unrealizedGainLossBuilding = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Unrealized gain-loss - Building'
      )
    )

    const unrealizedGainLossLand = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Unrealized gain-loss - Land'
      )
    )

    const totalAssets = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Total assets')
    )

    const totalLiabilities = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Total liabilities')
    )

    const totalCurrentLiabilities = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Total current liabilities'
      )
    )

    const accountsPayable = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Accounts payable')
    )

    const dueToFlockManagementCompany = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Due to Flock management company'
      )
    )

    const proeprtyManagementFeePayable = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Property management fee payable'
      )
    )

    const interestPayable = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Interest payable')
    )

    const otherPayables = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Other payables')
    )

    const accruedExpense = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Accrued expense')
    )

    const prepaidRent = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Prepaid rent')
    )

    const prepaidContribution = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Prepaid contribution')
    )

    const distributionsWithdrawalsPayable = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Distributions + withdrawals payable'
      )
    )

    const propertyInsurancePayable = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Property insurance payable'
      )
    )

    const propertyTaxesPayable = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Property taxes payable'
      )
    )

    const petDeposits = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Pet deposits')
    )

    const securityDeposits = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Security deposits')
    )

    const utilitiesPayable = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Utilities payable')
    )

    const repairsPayable = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Repairs payable')
    )

    const assetManagementFeePayable = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Asset management fee payable'
      )
    )

    const deferredRevenue = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Deferred revenue')
    )

    const securityDepositClearing = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Security deposit clearing'
      )
    )

    const totalLongTermLiabilities = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Total long-term liabilities'
      )
    )

    const loansPayable = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Loans payable')
    )

    const debtIssuanceCost = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Debt issuance cost')
    )

    const totalEquity = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Total equity')
    )

    const equityFromInvestors = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Total equity from investors'
      )
    )

    const contributions = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Contributions')
    )

    const distributionsAndWithdrawals = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Distributions and withdrawals'
      )
    )

    const totalRetainedEarnings = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Total retained earnings'
      )
    )

    const retainedEarnings = convertRowAmount(
      currentQuarterData?.find((row) => row.rowName === 'Retained earnings')
    )

    const priorYearRetainedEarnings = convertRowAmount(
      currentQuarterData?.find(
        (row) => row.rowName === 'Prior year retained earnings'
      )
    )

    const totalLiabilitiesAndEquity = formatDollarsAndCentsInvestor(
      (currentQuarterData?.find((row) => row.rowName === 'Total liabilities')
        ?.amountCents || 0) /
        100 +
        (currentQuarterData?.find((row) => row.rowName === 'Total equity')
          ?.amountCents || 0) /
          100,
      false,
      false
    )

    const buildingLandDollars = formatDollarsAndCentsInvestor(
      buildingInt + landInt,
      false,
      false
    )

    setSelectedQuarterBalanceSheetData({
      quarterString: selectedQuarter,
      totalCurrentAssets,
      totalCashAndRestrictedCash,
      totalAccountsReceivable,
      dueFromPropertyManager,
      dueFromPropertyManagerSecurityDeposits,
      accountsReceivable,
      accruedInterestReceivable,
      insuranceClaimsReceivable,
      badDebtWriteOff,
      utilityReimbursementReceivable,
      otherReceivables,

      totalOtherCurrentAssets,
      prepaidAssociationDues,
      prepaidFees,
      prepaidPropertyInsurance,
      prepaidPropertyTaxes,
      utilityDeposits,
      escrowDeposits,
      otherDeposits,
      treasurySecurities,

      totalFixedAssets,
      buildings: buildingLandDollars,
      land,
      buildingImprovementsStabilizing,
      buildingImprovementsStatusQuo,
      unrealizedGainLoss,
      unrealizedGainLossBuilding,
      unrealizedGainLossLand,

      totalAssets,

      totalLiabilities,
      totalCurrentLiabilities,

      accountsPayable,
      dueToFlockManagementCompany,
      proeprtyManagementFeePayable,
      interestPayable,
      otherPayables,
      accruedExpense,
      prepaidRent,
      prepaidContribution,
      distributionsWithdrawalsPayable,
      propertyInsurancePayable,
      propertyTaxesPayable,
      petDeposits,
      securityDeposits,
      utilitiesPayable,
      repairsPayable,
      assetManagementFeePayable,
      deferredRevenue,
      securityDepositClearing,

      totalLongTermLiabilities,
      loansPayable,
      debtIssuanceCost,

      totalEquity,
      equityFromInvestors,
      contributions,
      distributionsAndWithdrawals,

      totalRetainedEarnings,
      retainedEarnings,
      priorYearRetainedEarnings,

      totalLiabilitiesAndEquity,
    })
  }, [balanceSheetData, selectedQuarter])

  return {
    selectedQuarterBalanceSheetData,
    selectedQuarter,
    quarters,
    setSelectedQuarter,
  }
}

export default useBalanceSheetPage
