import { Info } from '@mui/icons-material'
import { Box, styled, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { PerformanceSummaryTableComponentProps } from './performanceSummaryTableTypes'

const StyledInfoOutline = styled(Info)({
  width: '16px',
  height: '16px',
})

const StyledInfoTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

const PerformanceSummaryTableTablet = (
  props: PerformanceSummaryTableComponentProps
) => {
  const {
    totalContributionString,
    totalEquityValueString,
    totalFundValueChangeString,
    totalCashTakenString,
    totalTaxFeeString,
  } = props

  return (
    <Box
      display="flex"
      flexDirection="column"
      // justifyContent="space-between"
      borderRadius="8px"
      bgcolor="green0.main"
      p="16px"
      gap="8px"
      width="100%"
      id="performance"
    >
      <Box>
        <Typography variant="cta2" fontWeight={500} color="moneyGreen.main">
          Performance &nbsp;
        </Typography>
        <Typography variant="cta2" fontSize="12px" color="moneyGreen.main">
          (Since Inception)
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="4px"
        alignSelf="stretch"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          alignSelf="stretch"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="p3" color="moneyGreen.main">
              Total contributions &nbsp;
            </Typography>
            <StyledInfoTooltip
              title={
                <Typography variant="p3" marginRight="6px" color="gray8.main">
                  This includes all equity invested in Flock from property or
                  cash contributions. Quarterly cash flow is not included, even
                  if you stay invested.
                </Typography>
              }
              enterTouchDelay={0}
              data-cy="initialContributionsTooltip"
            >
              <StyledInfoOutline fontSize="small" color="disabled" />
            </StyledInfoTooltip>
          </Box>

          <Typography variant="p3" color="moneyGreen.main">
            {totalContributionString}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          alignSelf="stretch"
        >
          <Typography variant="p3" color="moneyGreen.main">
            Fund value change
          </Typography>
          <Typography variant="p3" color="moneyGreen.main">
            {totalFundValueChangeString}
          </Typography>
        </Box>
        <hr
          style={{
            width: '100%',
            marginTop: '4px',
            marginBottom: '4px',
            color: 'gray3.main',
          }}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          alignSelf="stretch"
        >
          <Typography variant="p3" color="moneyGreen.main">
            Account value
          </Typography>
          <Typography variant="p3" color="moneyGreen.main">
            {totalEquityValueString}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          alignSelf="stretch"
        >
          <Typography variant="p3" color="moneyGreen.main">
            Cash accessed &nbsp;
            <StyledInfoTooltip
              title={
                <Typography variant="p3" marginRight="6px" color="gray8.main">
                  Cash accessed is inclusive of actual cash flow paid out and
                  any Top Up penalty charged.
                </Typography>
              }
              enterTouchDelay={0}
              data-cy="cashAccessedTooltip"
            >
              <StyledInfoOutline fontSize="small" color="disabled" />
            </StyledInfoTooltip>
          </Typography>
          <Typography variant="p3" color="moneyGreen.main">
            {totalCashTakenString}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          alignSelf="stretch"
        >
          <Typography variant="p3" color="gray5.main">
            Taxes paid by Flock
          </Typography>
          <Typography variant="p3" color="gray5.main">
            {totalTaxFeeString}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PerformanceSummaryTableTablet
